import Api from "@/services/api";
import {removeEmptyParams, downloadData} from "@utils";

export default {
    findSyntheseByYear(year) {
        return Api().get("/bilancircuit/synthese/" + year).then(res => {
            return res.data;
        });
    },
    findSyntheseByCurrentYear() {
        return Api().get("/bilancircuit/synthese").then(res => {
            return res.data;
        });
    },
    findAll(filter) {
        return Api().get("/bilancircuits", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findAllYears() {
        return Api().get("/bilancircuits/years/").then(res => res.data);
    },
    findAllCurrent(filter) {
        return Api().get("/bilancircuits/current", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findAllByYear(year) {
        return Api().get("/bilancircuits/year/" + year).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findById(id) {
        return Api().get("/bilancircuit/" + id).then(res => this.formatRes(res.data));
    },
    findByCeiId(ceiId) {
        return Api().get("/bilancircuits/cei/" + ceiId).then(res => this.formatRes(res.data));
    },
    findBoolByCeiId(ceiId) {
        return Api().get("/bilancircuits/bool/cei/" + ceiId).then(res => this.formatRes(res.data));
    },
    findCurrentYear() {
        return Api().get("/bilancircuit/currentyear").then(res => res.data);
    },
    create(bilanCircuit) {
        return Api().post("/bilancircuit", bilanCircuit).then(res => this.formatRes(res.data));
    },
    update(bilanCircuit) {
        return Api().put("/bilancircuit/" + bilanCircuit.id, bilanCircuit).then(res => this.formatRes(res.data));
    },
    delete(bilanCircuit) {
        return Api().delete("/bilancircuit/" + bilanCircuit.id).then(() => bilanCircuit);
    },
    formatRes(e) {
        return e;
    },
    download(year) {
        return Api().get("/bilancircuit/download/" + year, {responseType: "blob"}).then(res => downloadData(res));
    }
}