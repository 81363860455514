export default {
	methods: {
		sortCircuitList(circuitList) {
			circuitList.sort((a,b) => {
				let c1 = a.agerLabel.localeCompare(b.agerLabel);
				if(c1 !== 0) return c1;
				let c2 = a.uerLabel.localeCompare(b.uerLabel);
				if(c2 !== 0) return c2;
				let c3 = a.ceiLabel.localeCompare(b.ceiLabel);
				if(c3 !== 0) return c3;
				return a.number.localeCompare(b.number);
			});
		},
		
		initCircuitListForTable(circuitList, alreadySorted) {
			let cnb = circuitList.length;
			if(cnb===0) return;
			if (!alreadySorted) this.sortCircuitList(circuitList);
			
			let currentAgerId = circuitList[cnb-1].agerId;
			let currentUerId = circuitList[cnb-1].uerId;
			let currentCeiId = circuitList[cnb-1].ceiId;
			
			let currentAgerSize = 0;
			let currentUerSize = 0;
			let currentCeiSize = 0;
			
			for(let i=cnb-1;i>=0;i--) {
				let circuit = circuitList[i];
				
				if(currentAgerId!==circuit.agerId) {
					circuitList[i+1].agerSize = currentAgerSize;
					currentAgerSize=0;
					currentAgerId=circuit.agerId;
				}
				if(currentUerId!==circuit.uerId) {
					circuitList[i+1].uerSize = currentUerSize;
					currentUerSize=0;
					currentUerId=circuit.uerId;
				}
				if(currentCeiId!==circuit.ceiId) {
					circuitList[i+1].ceiSize = currentCeiSize;
					currentCeiSize=0;
					currentCeiId=circuit.ceiId;
				}
				
				currentAgerSize++;
				currentUerSize++;
				currentCeiSize++;
			}
			
			circuitList[0].agerSize = currentAgerSize;
			circuitList[0].uerSize = currentUerSize;
			circuitList[0].ceiSize = currentCeiSize;
		},
		
		initCircuitListForMaterielTable(circuitList, muleList) {
			let cnb = circuitList.length;
			if(cnb===0) return;
			this.sortCircuitList(circuitList);
			
			for (let i=0; i<cnb; i++) {
				if (circuitList[i].agerId !== circuitList[i+1].agerId) {
					muleList.forEach((mule) => {
						if (mule.agerId === circuitList[i].agerId) {
							mule.agerLabel = circuitList[i].agerLabel;
							circuitList.splice(i + 1, 0, mule);
							i++;
						}
					});
					circuitList.splice(i+1, 0, {total: true, agerLabel: circuitList[i].agerLabel, agerIndex: circuitList[i].agerId});
					i++;
				}
			}
			muleList.forEach((mule) => {
				if (mule.agerId === circuitList[cnb-1].agerId) {
					mule.agerLabel = circuitList[cnb-1].agerLabel;
					circuitList.push(mule);
				}
			});
			circuitList.push({total: true, agerLabel: circuitList[cnb-1].agerLabel, agerIndex: circuitList[cnb-1].agerId});
			
			this.initCircuitListForTable(circuitList, true);
		},
		
		initCircuitListForClassicDailySummaryTable(circuitList, forecastSheetList, linkSheetList, useSnapshotId) {
			circuitList.forEach((c, idx) => {
				forecastSheetList.forEach(fs => {
					if(useSnapshotId){
						if (c.ceiId === fs.forecastSheet.snapshotCeiId) circuitList[idx].forecastSheet = fs;
					}else {
						if (c.ceiId === fs.forecastSheet.ceiId) circuitList[idx].forecastSheet = fs;
					}
				});
				linkSheetList.forEach(ls => {
					let match = false;
					if(useSnapshotId){
						if (c.ceiId === ls.linkSheet.snapshotCeiId) match = true;
					}else {
						if (c.ceiId === ls.linkSheet.ceiId) match = true;
					}
					if (match) {
						if (ls.linkSheet.type === 'START') circuitList[idx].linkTypeStart = 1;
						if (ls.linkSheet.type === 'INTERMEDIARY') circuitList[idx].linkTypeInter = 1;
						if (ls.linkSheet.type === 'END') circuitList[idx].linkTypeEnd = 1;
						circuitList[idx].linkSheet = ls.linkSheet;
					}
					ls.linkRows.forEach(lr => {
						if(useSnapshotId){
							if (c.id === lr.snapShotCircuitId) circuitList[idx].linkRow = lr;
						}else{
							if (c.id === lr.circuitId) circuitList[idx].linkRow = lr;
						}
						
					});
				});
			});
			this.initCircuitListForTable(circuitList,useSnapshotId);
		}
	}
}